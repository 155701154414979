var lv_provider = null;
var lv_signer = null;
var bsc1stNftMainAddr = '0xB316F735AEBEeCA01a8B9B97CCbB52acd897167B';
var bridge1stMainAddr = '0x8457F71dCbE04CD1b69B733212D4C8DEb186E97e';
var bsc1stNftTestAddr = '0x4E88588F663BE3313764361F4F793FD42cD9946C';
var bridge1stTestAddr = '0x2753E4F8D333e7A408274f6AF8ca7D02C6f5b546';

var bsc4thNftMainAddr = '0xa6d99b7269676f337125FD7f2664C17bf611FC2B';
var bridge4thMainAddr = '0x07C56CB00b3FeC26EE26E19Be6e12DDA35Af946f';
var bsc4thNftTestAddr = '0xe4A75cb6969eB5392B81a0222aea407Af34330C7';
var bridge4thTestAddr = '0x731d7205C76aEb969828784dccc5Fa6eF950981d';


const abi =
[
    "function balanceOf(address owner) view returns (uint256)",
    "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)",
    "function setApprovalForAll(address operator, bool approved)",
		"function isApprovedForAll(address owner, address operator) view returns (bool)",

    "function lock(address collection, uint256[] tokenIds, uint256[] amounts)"
];

import AppConfig from '@/App.Config.js'
var gConfig = AppConfig();
import store from '../store'

export default function BscBridge() {
return {
/// Start

async checkMetamask() {

    var network = 'NONE';   
    var bscAddr = store.getters.getBscAddr;

    if (typeof window.ethereum !== 'undefined') {
        try {
            var chainId = await ethereum.request({method :'eth_chainId'});
            console.log("chain Id : " + chainId);

            if(chainId === bscAddr.Network) {
                network = 'BSC';
            }
        }
        catch(err) {
            console.error("Error] : " + err.message);
            gConfig.setNetwork(network);
            return network;
        }
    } else {
        console.error('[WalletAPI] checkMetamask() MetaMask is not installed!');
    }

    gConfig.setNetwork(network);
    return network;
},

async getBinanceNftBalance(ownerAddress, callback) {

	if(!lv_provider) {
		lv_provider = new ethers.providers.Web3Provider(window.ethereum);
	}
    
  const rv = await this.checkMetamask()
	if(rv!='NONE') {
		var contract1st = null;
		var contract4th = null;
		if(rv != 'BSC') {
			return;
		}
		try {
			contract1st = new ethers.Contract(gConfig.isProd ? bsc1stNftMainAddr : bsc1stNftTestAddr, abi, lv_provider);
			contract4th = new ethers.Contract(gConfig.isProd ? bsc4thNftMainAddr : bsc4thNftTestAddr, abi, lv_provider);
		}
		catch(err) {
			callback({res_code:401, message:'Create Contract', balance: 0})
		}
		if(!contract1st) return ;
		var ret1st = await contract1st.balanceOf(ownerAddress);
		var balance1st = ret1st.toString();

		var ret4th = await contract4th.balanceOf(ownerAddress);
		var balance4th = ret4th.toString();

		if(ret1st !== null && ret4th !== null) {
				console.log("[BscBridge] getBinanceNftInfo, owner address : ", ownerAddress);
				callback({res_code:200, message:'Success on get Binance NFT Balance', data:
				{
					balance1st,
					balance4th
				}
			})
		}
		else {
				console.log("[BscBridge] getBinanceNftInfo() ret == null, ==> balance of dvi : 0");
				callback({res_code:401, message:'Error on getting Binance NFT Balance'})
		}
	}else{
			console.log("checkMetamask error");
			callback({res_code:402, message:'Error on checking MetaMask'})
	}
},

async getOwnedNftId(round, ownerAddress, index) {

	if(!lv_provider) {
		lv_provider = new ethers.providers.Web3Provider(window.ethereum);
	}

 	const rv = await	this.checkMetamask()
		if(rv != 'NONE') {
		
		if(rv != 'BSC') {
			return;
		}

		let bscNftAddr = "";

		if(round == 1) {
			bscNftAddr = gConfig.isProd ? bsc1stNftMainAddr : bsc1stNftTestAddr;
		} else if(round == 4) {
			bscNftAddr = gConfig.isProd ? bsc4thNftMainAddr : bsc4thNftTestAddr;
		}
		var contract = null;
		try {
			contract = new ethers.Contract(bscNftAddr, abi, lv_provider);
		}
		catch(err) {
			return {res_code:401, message:'Create Contract'};
		}
		if(!contract) return ;

		var ret = await contract.tokenOfOwnerByIndex(ownerAddress, index);
		var nftId = ret.toString();

		if(ret !== null) {
			console.log("[BscBridge] owner address: ", ownerAddress, ", index : ", index, ", NFT ID : ", nftId);
			return {res_code:200, message:'Success on get DVI Balance', data:{index:index, id: nftId}};
		}
		else {
			console.log("[BscBridge] getDviBalance() ret == null, ==> balance of dvi : 0");
			return {res_code:401, message:'Error on getting DVI Balance'};
		}
	} else{
		console.log("checkMetamask error");
		return {res_code:402, message:'Error on checking MetaMask'};
	}
},

async lockBianceNft(round, tokenIds, amounts) {
    if(!lv_provider || !lv_signer) {
		lv_provider = new ethers.providers.Web3Provider(window.ethereum);
        lv_signer = lv_provider.getSigner();
	}

  const rv = await this.checkMetamask();
	if(rv!='NONE') {
		var contract = null;

		if(rv != 'BSC') {
			return {res_code:403};
		}

		let bridgeAddr;
		if(round == 1) {
			bridgeAddr = gConfig.isProd ? bridge1stMainAddr : bridge1stTestAddr;
		} else if(round == 4) {
			bridgeAddr = gConfig.isProd ? bridge4thMainAddr : bridge4thTestAddr;
		}

		try {
			console.log("Lock", bridgeAddr);
			contract = new ethers.Contract(bridgeAddr, abi, lv_signer);
		}
		catch(err) {
			return {res_code:401, message:'Create Contract'};
		}
		if(!contract) return {res_code:403};

		tokenIds = Object.values(tokenIds);
		amounts = Object.values(amounts);
		console.log({tokenIds, amounts});

		let bscNftAddr = "";

		if(round == 1) {
			bscNftAddr = gConfig.isProd ? bsc1stNftMainAddr : bsc1stNftTestAddr;
		} else if(round == 4) {
			bscNftAddr = gConfig.isProd ? bsc4thNftMainAddr : bsc4thNftTestAddr;
		}
		console.log({bscNftAddr});
		await contract.lock(bscNftAddr, tokenIds, amounts);
		return {res_code:200};
	}else{
		return {res_code:402, message:'Error on checking MetaMask'};
	}
},

async setApproveAll(binanceNftbalance1st, binanceNftbalance4th, ownerAddress, callback) {
    if(!lv_provider || !lv_signer) {
			lv_provider = new ethers.providers.Web3Provider(window.ethereum);
			lv_signer = lv_provider.getSigner();
		}

    this.checkMetamask().then(async (rv)=>{
		if(rv!='NONE') {
			
			if(rv != 'BSC') {
				return;
			}

			if(binanceNftbalance1st != 0) {
				let res = await this.setApproveAllContract(
					gConfig.isProd ? bsc1stNftMainAddr : bsc1stNftTestAddr, 
					gConfig.isProd ? bridge1stMainAddr : bridge1stTestAddr,
					ownerAddress
				);
				if(res.res_code != 200) {
					callback({res_code: res.res_code, message: res.message});
					return;
				}
			}

			if(binanceNftbalance4th != 0) {
				let res = await this.setApproveAllContract(
										gConfig.isProd ? bsc4thNftMainAddr : bsc4thNftTestAddr, 
										gConfig.isProd ? bridge4thMainAddr : bridge4thTestAddr,
										ownerAddress
									);
				if(res.res_code != 200) {
					callback({res_code: res.res_code, message: res.message});
					return;
				}
			}

			callback({res_code: 200});
			
		}else{
			callback({res_code:402, message:'Error on checking MetaMask'})
		}
	});
},

async setApproveAllContract(bscNftAddr, bridgeAddr, ownerAddress) {
	var contract = null;
	try {
		contract = new ethers.Contract(bscNftAddr, abi, lv_signer);
	}
	catch(err) {
		return { res_code:401, message:'Create Contract' };
	}
	let isApproveAll = await contract.isApprovedForAll(ownerAddress, bridgeAddr);
	if(isApproveAll) {
		return { res_code:200, message:'Success on approval.' };
	}

	var sendTransactionPromise = null;
	sendTransactionPromise = await contract.setApprovalForAll(bridgeAddr, true);

	if(!sendTransactionPromise) {
		return { res_code:402, message:'Unsupported type of sendTransactionPromise' };
	}

	var txReceipt = await sendTransactionPromise.wait();

	if (typeof txReceipt !== 'undefined') {
		if (txReceipt.status == 1) {
		return { res_code:200, message:'Success on approval.' };
		} else {
		return { res_code:402, message:'Error. Tx status is not completed.' };
		}
	} else {
		return { res_code:402, message:'Error on sendTransactionPromise() <br> with no receipt' };
	}
}

//// END ////
}}
